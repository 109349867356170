import { authApi } from '@core/app/api/authApi';
import { contactsApi } from "@core/app/api/contactsApi";
import { globalApi } from "@core/app/api/globalApi";
import { loggedWorkApi } from "@core/app/api/loggedWorkApi";
import { logworkApi } from '@core/app/api/logworkApi';
import { requestsApi  } from '@core/app/api/requestsApi';
import { postsApi } from "@core/app/api/postsApi";
import { profileApi } from "@core/app/api/profileApi";
import { settingsApi } from "@core/app/api/settingsApi";
import absenceReducer from '@core/app/slices/absence';
import authReducer from '@core/app/slices/auth';
import loggedWorkReducer from '@core/app/slices/logged-work';
import logworkReducer from '@core/app/slices/logwork';
import profileReducer from '@core/app/slices/profile';
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { absenceApi } from '../api/absenceApi';
import { employeeApi } from '../api/employeeApi';

const store = configureStore({
    reducer: {
        auth: authReducer,
        profile: profileReducer,
        logwork: logworkReducer,
        absence: absenceReducer,
        loggedWork: loggedWorkReducer,
        [authApi.reducerPath]: authApi.reducer,
        [postsApi.reducerPath]: postsApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [globalApi.reducerPath]: globalApi.reducer,
        [settingsApi.reducerPath]: settingsApi.reducer,
        [contactsApi.reducerPath]: contactsApi.reducer,
        [logworkApi.reducerPath]: logworkApi.reducer,
        [requestsApi.reducerPath]: requestsApi.reducer,
        [employeeApi.reducerPath]: employeeApi.reducer,
        [absenceApi.reducerPath]: absenceApi.reducer,
        [loggedWorkApi.reducerPath]: loggedWorkApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
      authApi.middleware, 
      postsApi.middleware, 
      profileApi.middleware, 
      globalApi.middleware, 
      settingsApi.middleware, 
      contactsApi.middleware, 
      logworkApi.middleware,
      requestsApi.middleware,
      employeeApi.middleware,
      absenceApi.middleware,
      loggedWorkApi.middleware
    )
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

